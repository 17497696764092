import React from 'react';

// components
import {
  Layout,
  Seo,
  Section,
  PageContent,
  Title,
  StyledLink,
} from '@/components';

/**
 * The site's 404 page
 */
const NotFoundPage = () => (
  <Layout withSidebar>
    <Seo title="Not Found | Bringing Glory" />
    <Section>
      <PageContent>
        <Title>404 (Page Not Found)</Title>
        <p>Sorry! We can't find the page you were looking for.</p>
        <p>It may have been moved, deleted, or never existed at all!</p>
        <p>
          Please head back to our{' '}
          <StyledLink to="/" color="primary" hoverColor="primary-hover">
            home page
          </StyledLink>
        </p>
      </PageContent>
    </Section>
  </Layout>
);

export default NotFoundPage;
